@use './inconsolata.font.scss';
@use './breakpoint.scss';

[data-mode='TECH'] {
  background-color: rgb(33, 34, 45);

  .logo {
    margin: 0 0 0 46px;

    @include breakpoint.mobile {
      margin: 0;
    }
  }

  #root {
    height: 100vh;
    height: 100svh;
    box-sizing: border-box;
    padding: 50px 40px 40px 0px;
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-template-rows: 50px 1fr;
    grid-template-areas:
      'logo content'
      'switcher content';

    @include breakpoint.mobile {
      height: calc(100svh - env(safe-area-inset-bottom, 0px));
      padding: 10px;
      grid-template-columns: 1fr;
      grid-template-rows: 70px 1fr 40px;
      grid-template-areas:
        'logo'
        'content'
        'switcher';
    }

    .page-not-found {
      font-family: 'Inconsolata';
      p {
        color: white;
      }
    }
  }

  .content {
    grid-area: content;
    font-family: 'Inconsolata';
    font-size: 18px;
    line-height: 22px;
    display: grid;
    grid-template-areas:
      'output output'
      'prompt input'
      'empty empty';
    grid-template-rows: min-content min-content 1fr;
    grid-template-columns: max-content 1fr;

    @include breakpoint.mobile {
      font-size: 16px;
    }

    > output {
      overflow: auto;
      max-height: calc(100vh - 115px);
      grid-area: output;
      font-family: inherit;
      background: transparent;
      color: inherit;
      border: 0;
      white-space: pre-line;

      @include breakpoint.mobile {
        max-height: calc(100svh - env(safe-area-inset-bottom, 0px) - 160px);
      }

      > .err {
        color: #f42c3e;
      }

      > .out {
        color: #d5c4a1;
      }

      > .out.cmd-cat {
        color: #fbf1c7;
      }

      > .completion {
        color: #928374;
      }

      h1 {
        font-size: 24px;
        line-height: 1.2;
        margin: 16px 0 0 0;
      }

      h2 {
        margin: 0;
        font-size: 20px;
        line-height: 22px;
      }

      h3 {
        margin: 0;
        font-size: 18px;
        line-height: 22px;
      }

      p {
        margin: 0;
      }

      a {
        color: #99c6ca;
        &:hover {
          color: #3e588c;
        }
      }

      img {
        width: 100%;
        max-width: 600px;
        border-radius: 4px;

        &.screenshot.mobile {
          max-height: 500px;
          width: auto;
        }
      }

      .word-cloud {
        > ul {
          margin: 0;
          padding: 0;
          white-space: normal;
          list-style: none;

          > li {
            display: inline;
            &:not(:last-child)::after {
              content: ' | ';
            }
          }
        }
      }
    }

    .prompt {
      grid-area: prompt;
      max-width: fit-content;
      color: #888888;
      white-space: pre;
    }

    .command {
      color: #fabd2f;
    }

    > .input {
      grid-area: input;
      white-space: pre;
      color: #fabd2f;
    }

    > :focus {
      outline: none;
    }

    .cli-tag-line {
      margin: -11px 0 0 0;
      font-size: 12px;
      line-height: 1;

      @include breakpoint.mobile {
        font-size: 9px;
        margin: 0;
      }

      .address {
        font-size: 14px;
        line-height: 18px;

        @include breakpoint.mobile {
          font-size: 10px;
        }
      }

      a {
        color: #fbf1c7;
        text-decoration: none;

        &:hover {
          color: #3e588c;
        }
      }
    }
  }
}
