@use './plain-thin.font.scss';
@use './breakpoint.scss';

.switcher {
  font-family: 'PlainThin';
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  list-style: none;
  height: 28px;
  position: fixed;
  left: 50px;
  bottom: 40px;
  align-self: end;
  justify-self: start;
  grid-area: switcher;
  isolation: isolate;

  &::after {
    content: ' ';
    display: block;
    background: var(--yatah-orange);
    height: 14px;
    width: 14px;
    border-radius: 7px;
    position: absolute;
    top: 13px;
    left: 1px;
    margin: 0 auto 0 -3px;
    z-index: 0;
    transition: left 200ms ease-in-out;
  }

  &.mode--tech {
    color: white;
  }

  &.mode--tech:hover::after,
  &.stay-open.mode--tech::after {
    left: calc(100% - 8px);
  }

  &.mode--fun:hover::after,
  &.stay-open.mode--fun::after {
    left: calc(50% - 4px);
  }

  &::before {
    content: ' ';
    height: 16px;
    width: calc(100% + 8px);
    left: -4px;
    top: 12px;
    border-radius: 8px;
    background: rgba(230, 230, 230, 0);
    transition: background 200ms ease-in-out;
    position: absolute;
    z-index: 0;
  }

  &:hover::before,
  &.stay-open::before {
    background: rgba(230, 230, 230, 1);
  }

  & > li {
    position: relative;
    line-height: 1;
    font-size: 9px;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    cursor: pointer;
    height: 100%;
    display: block;
    z-index: 1;
    padding: 0 4px;
  }

  &:hover > li,
  &.stay-open > li {
    opacity: 1;
  }

  @include breakpoint.mobile {
    position: relative;
    bottom: auto;
    left: auto;
  }

  @include breakpoint.no-hover {
    &::before {
      background: rgba(230, 230, 230, 1);
    }

    & > li {
      opacity: 1;
    }

    &.mode--tech::after {
      left: calc(100% - 8px);
    }

    &.mode--fun::after {
      left: calc(50% - 4px);
    }
  }
}
