@mixin mobile {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin below_960 {
  @media only screen and (max-width: 960px) {
    @content;
  }
}

@mixin no-hover {
  @media only screen and (hover: none) {
    @content;
  }
}
