@use './switcher.scss';
@use './plain.scss';
@use './tech.scss';
@use './fun.scss';
@use './breakpoint.scss';

:root {
  --yatah-orange: rgb(255, 71, 38);
  --yatah-black: rgb(41, 33, 26);
  --yatah-light-gray: rgb(200, 200, 200);
  --bp-md: 768px;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  transition: background-color 400ms ease-in-out;
}

.logo {
  height: 58px;
  margin: -10px auto 0 auto;
  grid-area: logo;

  & .logo--shape {
    fill: var(--yatah-black);
    transition: fill 400ms ease-in-out 200ms;

    [data-mode='TECH'] & {
      fill: rgb(111, 106, 80);
    }
  }

  @include breakpoint.mobile {
    height: 48px;
    margin: 0;
  }
}

[data-mode='PLAIN'],
[data-mode='TECH'],
[data-mode='FUN'] {
  #root {
    .page-not-found {
      text-align: center;
      font-family: 'PlainThin';

      h1 {
        color: var(--yatah-orange);
        font-size: 120px;
        margin: 250px 0 0 0;
        line-height: 1;
      }

      p {
        font-weight: bold;
        font-size: 24px;
        margin: 0;
      }
    }
  }
}
