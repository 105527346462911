@use './breakpoint.scss';

[data-mode='FUN'] {
  background-color: rgb(255, 255, 255);

  #root {
    height: 100vh;
    height: 100svh;
    box-sizing: border-box;
    padding: 50px 40px 40px 0px;
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-template-rows: 50px 1fr;
    grid-template-areas:
      'logo content'
      'switcher content';

    @include breakpoint.mobile {
      height: calc(100svh - env(safe-area-inset-bottom, 0px));
      padding: 10px;
      grid-template-columns: 1fr;
      grid-template-rows: 50px 1fr 40px;
      grid-template-areas:
        'logo'
        'content'
        'switcher';
    }
  }

  .fun--canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .logo {
    position: relative;
    z-index: 1;
  }
  .switcher {
    z-index: 1;
  }
}
