@use './plain-thin.font.scss';
@use './breakpoint.scss';

[data-mode='PLAIN'] {
  background-color: rgb(255, 255, 255);

  #root {
    font-family: 'PlainThin';
    font-size: 16px;
    line-height: 28px;
    color: var(--yatah-black);
    min-height: 100vh;
    min-height: 100svh;
    box-sizing: border-box;
    padding: 50px 40px 40px 0px;
    display: grid;
    grid-template-columns: 150px 1fr;
    align-items: start;
    grid-template-rows: 36px 150px 1fr;
    grid-template-areas:
      'logo tagline'
      'logo navigation'
      'switcher content';

    @include breakpoint.mobile {
      padding: 24px;
      grid-template-rows: 50px 1fr 48px;
      grid-template-columns: 60px 1fr;
      grid-template-areas:
        'logo tagline'
        'content content'
        'switcher navigation';
    }

    .tag-line {
      font-size: 9px;
      line-height: 13px;
      grid-area: tagline;

      p {
        margin: 0;
      }

      @include breakpoint.mobile {
        font-size: 7px;
        line-height: 10px;
        margin-top: 9px;
      }
    }

    a {
      color: var(--yatah-black);
    }
    a:hover {
      color: var(--yatah-orange);
    }

    .highlight {
      color: var(--yatah-orange);
    }

    nav {
      grid-area: navigation;
      align-self: center;
      font-family: 'PlainThin';
      font-size: 14px;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
          display: inline-block;
          margin-right: 50px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      a {
        text-decoration: none;
        color: var(--yatah-black);
      }

      a.active {
        color: var(--yatah-orange);
      }

      .nav--hamburger-checkbox,
      .nav--hamburger-lines {
        display: none;
      }

      @include breakpoint.mobile {
        position: fixed;
        font-size: 32px;
        position: fixed;
        bottom: 10px;
        right: 0;
        left: 0;
        margin: 0 auto;
        width: 50px;
        height: 50px;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          border-radius: 25px;
          box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
          border: solid 1px rgba(150, 150, 150, 0.2);
          background-color: white;
          box-sizing: border-box;
        }

        .nav--hamburger-checkbox {
          display: block;
          height: 50px;
          width: 50px;
          opacity: 0;
          cursor: pointer;
          position: absolute;
          z-index: 4;
          appearance: none;
          padding: 0;
          margin: 0;
        }

        .nav--hamburger-menu {
          display: block;
          position: absolute;
          height: 16px;
          width: 28px;
          top: 17px;
          right: 11px;
          z-index: 3;
          border-top: solid 2px var(--yatah-black);
          box-sizing: border-box;

          &::after,
          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: var(--yatah-black);
          }

          &::before {
            top: 5px;
          }

          &::after {
            bottom: 0px;
          }
        }

        .nav--list {
          display: flex;
          position: fixed;
          height: 100vh;
          width: 100vw;
          top: 0;
          left: 0;
          padding-top: 100px;
          transition: transform 300ms ease-in-out, opacity 100ms ease-in-out;
          transform: translateY(150%);
          flex-direction: column;
          text-align: center;
          background: rgba(255, 255, 255, 0.9);
          z-index: 1;

          > li {
            margin: 16px 0;
          }
        }

        .nav--hamburger-checkbox:checked {
          & ~ .nav--list {
            display: flex;
            opacity: 1;
            transform: translateY(0);
          }

          & ~ .nav--hamburger-menu {
            border: none;
            &::before,
            &::after {
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
            &::before {
              transform: rotate(45deg);
            }
            &::after {
              transform: rotate(-45deg);
            }
          }
        }
      }
    }

    .content {
      margin: 25px 0 0 0;
      grid-area: content;

      h1 {
        margin: 0 0 40px 0;
        font-family: 'PlainThin';
        font-size: 60px;
        font-weight: 300;
        line-height: 66px;

        @include breakpoint.mobile {
          margin: 0 0 40px 0;
          font-size: 36px;
          line-height: 44px;
        }
      }

      h2 {
        font-weight: 300;
        font-size: 24px;
        margin: 80px 0 24px;
        line-height: 30px;

        @include breakpoint.mobile {
          margin: 60px 0 18px 0;
        }
      }

      h3 {
        font-weight: 300;
        font-size: 16px;
        margin: 32px 0 16px 0;
      }

      p {
        margin: 0 0 16px 0;
      }

      @include breakpoint.mobile {
        margin: 60px 10px 0;
      }

      & > div {
        max-width: 740px;
      }

      &.team {
        & > div {
          max-width: 1200px;

          & > h1,
          & > p {
            max-width: 740px;
          }
        }

        .all-members {
          margin-top: 32px;
          display: flex;
          gap: 60px;

          .team--member {
            flex: 1;
            img {
              width: 100%;
              border-radius: 4px;
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
            }
            h1 {
              font-size: 32px;
              margin: 8px 0 32px 0;
            }
          }

          @include breakpoint.mobile {
            flex-direction: column;
          }
        }
      }

      &.works {
        & > div {
          max-width: 1200px;

          & > h1,
          & > p,
          & .word-cloud {
            max-width: 740px;
          }
        }

        &.works--detail {
          & > div {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            gap: 40px;
            max-width: initial;

            @include breakpoint.mobile {
              gap: 24px 8px;
            }

            h1 {
              align-self: center;
              grid-column: span 6;
              @include breakpoint.below_960 {
                grid-column: span 12;
                margin-bottom: 0;
              }
            }

            .project--meta {
              font-size: 12px;
              line-height: 18px;
            }

            > img {
              width: 100%;
              border-radius: 4px;
            }

            .project--description,
            .screenshot.desktop {
              grid-column: span 6;
              @include breakpoint.below_960 {
                grid-column: span 12;
              }
            }

            .screenshot.desktop,
            .screenshot.interactive-mirror {
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
            }

            .screenshot.interactive-mirror {
              grid-column: span 4;
              @include breakpoint.below_960 {
                grid-column: span 6;
              }
            }

            .screenshot.mobile {
              grid-column: span 3;
              @include breakpoint.below_960 {
                grid-column: span 4;
              }

              @include breakpoint.mobile {
                grid-column: span 6;
              }
            }

            .screenshot.mobile.start-new-row {
              grid-column: 1 / span 3;
              @include breakpoint.below_960 {
                grid-column: 1 / span 4;
              }

              @include breakpoint.mobile {
                grid-column: 1 / span 6;
              }
            }
          }
        }
      }
    }

    .word-cloud {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        > li {
          display: inline-block;

          &:not(:last-child):after {
            content: ' | ';
          }
        }
      }
    }

    .work-list {
      margin: 120px 0 0 0;
      padding: 0;
      list-style: none;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, auto));
      gap: 60px 40px;

      @include breakpoint.mobile {
        margin: 40px 0 0 0;
        gap: 40px 24px;
      }

      li {
        a {
          text-decoration: none;

          &:hover {
            p {
              .client {
                color: var(--yatah-orange);
              }
            }
          }
        }

        img {
          width: 100%;
          object-fit: cover;
          border-radius: 4px;
          margin-bottom: 8px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        }

        h2 {
          margin: 0px 10px 4px 10px;
          line-height: 1;
        }

        p {
          font-size: 14px;
          margin: 0px 10px;
          line-height: 20px;

          .client {
            color: var(--yatah-light-gray);
          }
        }
      }
    }
  }
}
